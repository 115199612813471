import { React, useEffect } from "react";
import lineImage from "../images/caregiver-line.png";
import logoImage from "../images/bshc-logo.png";
// import respiteImage from "../images/image-for-respite.jpeg";
// import gpaImage from "../images/gita-unsplash.jpeg";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import ReactGA from "react-ga4";

const MessageComponent = () => {
  const gaEventTracker = useAnalyticsEventTracker("Boston Senior Home Care");

  // Get the token from the querystring parameter
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "BSHC Microsite" });
  }, []);

  return (
    <div className="container mt-5 background-custom h-100">
      <div className="px-lg-5 px-md-2 px-sm-1">
        <h1 className="custom-heading">CAREGIVER RESOURCES</h1>
        <img
          className="img-fluid"
          alt="caregiver photos"
          width="100%"
          src={lineImage}
        ></img>
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 mt-lg-4">
            <h2>They depend on you. You can depend on us.</h2>
            <h5>
              BSHC provides the services & support you might need — at no cost
              to you.
            </h5>
            <p>
              If you live in the City of Boston or provide long-distance care to
              someone who does, there are a ton of resources you can tap into.
              BSHC (not just for seniors!) can help get you there faster. Bonus?
              This is all available to <u>anyone</u> who cares for anyone else —
              children, spouses, grandparents, siblings, and more.
            </p>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 mt-5">
            <img
              className="img-fluid mx-auto d-block"
              alt="BSHC logo"
              src={logoImage}
              width="80%"
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <a
              href={`https://elderinfo.org/referrals/FCS_Referral_Form_CII.aspx?token=${token}`}
              target="_blank"
              onClick={() => gaEventTracker("BSHC Referral")}
              rel="noopener noreferrer"
              className=""
            >
              <button
                className="btn btn-square btn-lg pt-1 pb-1 pl-2 pr-2 mt-lg-3 mt-sm-1 "
                style={{ fontSize: "15px", width: "170px", height: "45px" }}
              >
                NEXT
              </button>
            </a>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12">
            <h2 className="custom-heading">
              Find support for the things driving your intensity.{" "}
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12 mt-2">
            <p>
              Here are just some of the things BSHC can do for you:

            </p>
            <ul>
              <li>Getting a stipend for the care you give</li>
              <li>Gift cards you can use on groceries, transportation and more</li>
              <li>Getting connected with Ring cameras, Echos and iPads</li>
              <li>Helping with the grandkids</li>
            </ul>
            <p>All at <span style={{
              fontFamily: "'Relative Pro Bold', 'Relative Pro', sans-serif",
              fontWeight: 900,
            }}>no cost</span> to you</p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <a
              href={`https://elderinfo.org/referrals/FCS_Referral_Form_CII.aspx?token=${token}`}
              target="_blank"
              onClick={() => gaEventTracker("BSHC Referral")}
              rel="noopener noreferrer"
              className=""
            >
              <button
                className="btn btn-square btn-lg pt-1 pb-1 pl-2 pr-2 mt-lg-3 mt-sm-1 "
                style={{ fontSize: "15px", width: "170px", height: "45px" }}
              >
                NEXT
              </button>
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12 mt-5">
            <p>
              If it’s helpful, you can also check out a{" "}
              <a
                target="_blank"
                style={{ color: "inherit", textDecoration: "underline" }}
                href="https://bshcinfo.org/other-resources/?utm_campaign=page%20test-2&utm_medium=landing%20page&utm_source=archangels%20microsite-1"
              >
                full list of community resources
              </a>{" "}
              available in Boston and the State of Massachusetts or explore the
              latest topics on the{" "}
              <a
                target="_blank"
                style={{ color: "inherit", textDecoration: "underline" }}
                href="https://bshcinfo.org/category/blog/?utm_campaign=page%20test-2&utm_medium=landing%20page&utm_source=archangels%20microsite-1"
              >
                BSHC blog
              </a>
              . Either way, find guidance on things like:
            </p>
          </div>
        </div>

        <div className="row mt-2 ml-6 mr-6">
          <div className="col-lg-5 col-sm-12 col-md-12">
            <h5 className="custom-heading">Knowing What to Expect</h5>
            <p>
              Access education and training on things like falls prevention and
              dementia care.
            </p>
          </div>
          <div className="col-lg-1 col-sm-12 col-md-12"></div>
          <div className="col-lg-6 col-sm-12 col-md-12">
            <h5 className="custom-heading">Making Time for You</h5>
            <p>
              Find services when you need to step away and need help taking care
              of the things you’ve set aside (like grocery shopping or
              connecting with friends).
            </p>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-6 col-sm-12 col-md-12  ml-6 mr-6">
            <h5 className="custom-heading">Guidance on Managing Money</h5>
            <p>
              Get financial and legal consultations for all kinds of caregiving
              situations including elder care and children/guardianship.
            </p>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-12">
            <h5 className="custom-heading">Addressing Family Matters</h5>
            <p>
              Join online support groups and meet others who get it. Plus, get
              support for things like mental health and wellness.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className=" d-flex justify-content-center align-items-center">
            <div className="text-center">
              <h1 className="display-4">Ready to take the first step?</h1>
              <h5 className="mt-5"> Call 617-292-6211.</h5>
            </div>
          </div>
        </div>
        <div className="row mt-5"></div>

        <div className="row mt-5"></div>

        <div className="row mt-5"></div>
      </div>
    </div>
  );
};

export default MessageComponent;
