// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MessageComponent from "./components/MessageComponent";
import "./App.css"; // Import your CSS file for global styles
import Header from "./components/Header.js";

import ReactGA from "react-ga4";
ReactGA.initialize("G-KH5THD6DTW");

const App = () => {
  return (
    <div className="app-container">
      <Header /> {/* Include the Header component */}
      <Router>
        <Routes>
          {/* Route for the root URL */}
          <Route path="/" element={<MessageComponent />} />
          {/* Add other routes here if needed */}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
