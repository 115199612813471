// Header.js
import React from "react";
import headerImage from "../images/logo.svg"; // Import your header image

function Header() {
  return (
    <header className="sticky-header app-container pt-4 ml-5">
      <div className="container">
        <div className="px-lg-5 px-md-2 px-sm-1">
          <div className="ml-5">
            <img
              src={headerImage}
              alt="Header"
              className="ml-5"
              width="190px"
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
